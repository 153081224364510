"use strict"
const tools = require('./tools');
import CookieAlert from "./CookieAlert";
import Cookie from "./Cookie";
import Collapsible from "./Collapsible";
import Galery from './Galery';
import Lazy from './Lazy';
import Form from "./Form";
import Swiper from 'swiper';

const main = {
    hideBWinContent: false,
    init() {
        document.documentElement.classList.remove('no-js');
        this.cookies();
        this.lazy();
        this.nav();
        this.project();
        let hasGalery = this.galery();
        this.form();
        this.collapsibles();
        this.bwControl();

        if(!hasGalery && document.body.id !== 'home') {
            this.initBulletsWaves();
        } else {
            this.hideBWinContent = true
        }
    },

    cookies() {
      new CookieAlert('#cookie_alert', '#cookie_consent').init();
    },

    lazy() {
        new Lazy({targets: '.lazy'});

        new Lazy({
            targets: '.lazy-bg',
            loadFn: elt => {
                const imgSrc = elt.dataset.lazy;
                if(imgSrc) {
                    const img = document.createElement('img');
                    img.onload = () => {
                        elt.style.backgroundImage = 'url("'+imgSrc+'")';
                    }
                    img.src = imgSrc;
                }
            }
        })
    },

    nav() {
        let pageHeaderEl = document.querySelector('#page-header');

        if(pageHeaderEl) {
            let navBtn = document.querySelector('.nav-btn');

            if(navBtn) {
                navBtn.addEventListener('click', e => {
                    pageHeaderEl.classList.toggle('nav-open');
                    document.dispatchEvent(new Event('navtoggle'));

                    if (pageHeaderEl.classList.contains('nav-open')) {

                        if (window.bwaves) {
                            window.bwaves.style.zIndex = 12;
                        }

                        if (this.hideBWinContent) {
                            setTimeout(() => {
                                this.startBulletWaves();
                            }, 200);
                        }

                    } else {

                        if (window.bwaves) {
                            window.bwaves.style.zIndex = -1;
                        }
                        if(this.hideBWinContent) {
                            this.stopBulletWaves();
                        }
                    }
                })
            }
        }
    },

    galery() {
        let galeryElts = document.querySelectorAll('.galeries');
        if(galeryElts.length > 0) {
            setTimeout(() => {
                tools.each(galeryElts, elt => {
                    let galery = new Galery(elt);
                    galery.init();
                })
            }, 200)
            return true;
        }
        return false;
    },

    project() {
        tools.each(document.querySelectorAll('.project-header'), el =>  {

            let sliderWrapper = el.querySelector('.swiper-container');
            let prevBtn = sliderWrapper.querySelector('.slider-prev');
            let nextBtn = sliderWrapper.querySelector('.slider-next');

            if(sliderWrapper) {
                new Swiper(sliderWrapper,  {
                    loop: true,
                    loopedSlides: 0,
                    simulateTouch: true,
                    slidesPerView: 1,
                    spaceBetween: 2,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 1,
                    },
                    navigation: {
                        prevEl: prevBtn,
                        nextEl: nextBtn
                    }
                })
            }
        })
    },

    form() {
        let formWrapperEls = document.querySelectorAll('.form-wrapper');
        tools.each(formWrapperEls ,formWrapperEl => {
            let formEl = formWrapperEl.querySelector('form');
            let statusEl = formWrapperEl.querySelector('.status')
            if(formEl && statusEl) {
                let form = new Form(formEl, statusEl);
                form.init();
            }
        })
    },

    collapsibles() {
        tools.each(document.querySelectorAll('.collapsible-wrapper'), collEl => {
            new Collapsible(collEl);
        })
    },

    bwControl() {
        let stopBGAnimBtn = document.getElementById('stop_waves');
        if(stopBGAnimBtn) {
            let cookie = new Cookie('usb-innovation-preventbw');
            if(cookie.check('true')) {
                stopBGAnimBtn.classList.add('inactive');
            }

            stopBGAnimBtn.addEventListener('click', () => {
                if(cookie.check('true')) {
                    cookie.set('false');
                    stopBGAnimBtn.classList.remove('inactive');
                    this.startBulletWaves();
                } else {
                    cookie.set('true');
                    stopBGAnimBtn.classList.add('inactive');
                    this.stopBulletWaves();
                }

            })
        }
    },

    startBulletWaves() {
        if(window.bwrestart && new Cookie('usb-innovation-preventbw').check('false')) {
            window.bwrestart();
        } else {
            this.initBulletsWaves();
            if(window.bwaves) {
                window.bwaves.style.zIndex = 12;
            }
        }
    },

    stopBulletWaves() {
        if(window.bwstop) {
            window.bwstop();
        }
    },

    initBulletsWaves() {
        if(!tools.isEditMode()) {
            let container = document.getElementById('bullets-wave');

            if(container) {
                let cookie = new Cookie('usb-innovation-preventbw');

                if(!cookie.check('true')) {
                    let script = document.createElement('script');
                    document.body.insertAdjacentElement('beforeend', script);
                    window.bwaves = container;
                    setTimeout(()=>{
                        script.src = window.location.context +'/.resources/usb-innovation-module/webresources/js/bullets.js';
                    }, 500);
                }
            }
        }
    }
};

window.sukoaUtils = {
    dcmadr: function (nnnn) {
        var a = "";
        for (var i = 0; i < nnnn.length; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};

document.addEventListener('DOMContentLoaded', () => {main.init();});

