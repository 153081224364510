import Lazy from "./Lazy";

const tools = require('./tools');
import Swiper from 'swiper';

class Galery {
    constructor(el) {
        this.el = el;
        this.wrapper = el.querySelector('.galeries-wrapper')
        this.slidersElt = this.wrapper.querySelectorAll('.swiper-container');
        this.overlayElt = el.querySelector('.overlay');
        this.overlaySwiperContainer = null;
        this.overlaySliderWrapper = null;
        this.overlay = null;
    }

    init() {
        if(this.slidersElt && this.overlayElt) {

            this.overlaySwiperContainer = this.overlayElt.querySelector('.swiper-container');
            this.overlaySliderWrapper = this.overlayElt.querySelector('.swiper-wrapper');

            tools.each(this.slidersElt, (el, i)=> {

                let prevBtn = el.querySelector('.slider-prev');
                let nextBtn = el.querySelector('.slider-next');

                el.slider = new Swiper(el, {
                    loop: true,
                    simulateTouch: true,
                    centeredSlides: false,
                    initialSlide: el.getAttribute('data-start') * 1,
                    slidesPerView: 'auto',
                    spaceBetween: 2,
                    threshold: 10,
                    navigation: {
                        prevEl: prevBtn,
                        nextEl: nextBtn
                    },
                });

                new Lazy({
                    targets: el.slider.slides,
                    loadFn: elt => {
                        const imgSrc = elt.dataset.lazy;
                        if(imgSrc) {
                            const img = document.createElement('img');
                            img.onload = () => {
                                elt.style.backgroundImage = 'url("'+imgSrc+'")';
                            }
                            img.src = imgSrc;
                        }
                    },
                    simLoadLimit: 1,
                    simLoadDelay: 500
                })


            })

            this.setClickHandlers();

            tools.each(this.slidersElt, (el, i)=> {
                el.slider.slideTo(el.getAttribute('data-start') * 1, 0);
            })
            this.el.classList.add('initialized');


            document.addEventListener('navtoggle', () => {
                this.hideOverlay();
            });
            document.addEventListener('keyup', e => {
                if(e.key === 'Escape' || e.key === 'Esc') {
                    this.hideOverlay()
                }
            })
        }
    }

    showOverlay() {
        if(this.overlay.slideElt && this.overlay.sliderElt) {
            let sliderElt = this.overlay.sliderElt;
            let slideElt = this.overlay.slideElt;

            this.overlaySliderWrapper.html = '';
            if(this.overlayElt.slider) {
                this.overlayElt.slider.destroy();
            }

            let cloneSlideFn = (slide) => {
                let clonedSlide = slide.cloneNode(true);
                let img = document.createElement('img');
                let src = clonedSlide.getAttribute('data-lazy');

                img.classList.add('swiper-lazy');
                img.setAttribute('data-src', src.replace('/small/', '/large/'));
                img.src = '';

                clonedSlide.appendChild(img);
                clonedSlide.setAttribute('data-lazy', '')
                this.overlaySliderWrapper.insertAdjacentElement('beforeend', clonedSlide);
            }
            tools.each(sliderElt.slider.slides, cloneSlideFn)


            let prevBtn = this.overlaySwiperContainer.querySelector('.slider-prev');
            let nextBtn = this.overlaySwiperContainer.querySelector('.slider-next');
            this.overlayElt.slider = new Swiper(this.overlaySwiperContainer,  {
                loop: false,
                simulateTouch: true,
                slidesPerView: 1,
                watchSlidesVisibility: true,
                preloadImages: false,
                keyboard: true,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    prevEl: prevBtn,
                    nextEl: nextBtn
                }
            })

            this.overlayElt.slider.slideTo(slideElt.dataset.index * 1, 0);

            this.el.classList.add('overlay-visible');

            this.overlayElt.slider.update();
            tools.goTo(this.overlayElt, () =>
                document.body.classList.add('fixed')
            );
        }
    }

    hideOverlay() {
        let visible = this.el.classList.contains('overlay-visible');
        if(visible) {
            document.body.classList.remove('fixed');
            this.el.classList.remove('overlay-visible');
            this.overlaySliderWrapper.innerHTML = '';
        }
        return visible;
    }

    setClickHandlers() {
        let slideClickHdl = (sliderElt, slideElt, e) => {

            if(sliderElt.classList.contains('active')) {
                this.hideOverlay();
            } else {
                this.overlay = {sliderElt: sliderElt, slideElt: slideElt};
                this.showOverlay();
            }
        }

        tools.each(this.slidersElt, sliderElt => {
            tools.each(sliderElt.slider.slides, slide => {
                slide.addEventListener('click', e => slideClickHdl(sliderElt, slide, e))
            })
        });

        let closeBtn = this.overlayElt.querySelector('.close');
        closeBtn.addEventListener('click', e => {
            this.hideOverlay();
        })
    }
}

export default Galery;
