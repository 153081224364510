const tools = require('./tools');

class Lazy {

    constructor(options = {
        targets: null,
        loadFn: null,
        threshold: 0.01,
        rootMargin: {top: 0, right: 0, left: 0, bottom: 0},
        persist: false,
        simLoadLimit: 1,
        simLoadDelay: 200,

    }) {
        this.elementList = [];

        this.rateLimit = new RateLimit(options.simLoadLimit || 1, options.simLoadDelay || 200, false)
        if(typeof options.targets === "string") {
            const targets = document.querySelectorAll(options.targets);
            if(targets.length > 0) {
                tools.each(targets, t => this.elementList.push(t));
            }
        } else {
            if(typeof options.targets.length === typeof undefined) this.elementList.push(options.targets);
            else tools.each(options.targets, elt => this.elementList.push(elt));
        }

        this.loadFn = options.loadFn || null;
        this.threshold = options.threshold || 0.01;
        this.rootMargin = options.rootMargin || {top: 0, right: 0, left: 0, bottom: 0};
        this.persist = options.persist;

        this.intersectionObserver = null;

        this.init();
    }

    init() {
        if(this.loadFn === null) {
            this.persist = false;
            this.loadFn = (elt) => {
                const imgSrc = elt.getAttribute('data-lazy');

                if(imgSrc) {
                    const img = document.createElement('img');
                    img.onload = () => {
                        elt.src = imgSrc;
                    }

                    img.src = imgSrc;
                }
            }
        }

        if (typeof IntersectionObserver !== typeof undefined) {
            this.proceedWithObserver();
        } else {
            this.proceedWithScrollEvent();
        }
    }

    execLoad(options={elt:null, index:-1, observer:null}) {

        if(this.persist || !options.elt.classList.contains('lazy-loaded')) {
            this.loadFn(options.elt);
            options.elt.classList.add('lazy-loaded')

            if(!this.persist) {
                if(options.index !== -1) {
                    this.elementList = this.elementList.splice(options.index, 1);
                }
                if(options.observer) {
                    options.observer.unobserve(options.elt);
                }
            }
        }
    }

    proceedWithObserver() {

        const callback = (entries, observer)  => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    let _this = this;
                    this.rateLimit.schedule(function() {
                        _this.execLoad({elt: entry.target, observer: observer})
                    })
                }
            })
        };

        this.intersectionObserver = new IntersectionObserver(callback, {threshold: this.threshold, rootMargin: this.getRootMarginString()});

        tools.each(this.elementList, elt => this.intersectionObserver.observe(elt));
    }

    proceedWithScrollEvent() {
        const fn = (lazy) => {

            if(lazy.elementList.length > 0) {
                let loadedIndex = []

                tools.each(lazy.elementList, (elt, i) => {

                    const topLimit = elt.getBoundingClientRect().top + elt.offsetHeight * lazy.threshold;
                    const bottomLimit = elt.getBoundingClientRect().bottom + elt.offsetHeight * lazy.threshold;

                    if(topLimit < window.innerHeight + lazy.rootMargin.top // to be tested
                        && bottomLimit > lazy.rootMargin.bottom) {
                        let _lazy = lazy;
                        this.rateLimit.schedule(function() {
                            _lazy.execLoad({elt: elt, index: -1});
                        })
                        loadedIndex.push(i);
                    }
                })
                if(!lazy.persist && loadedIndex.length > 0) {

                    lazy.elementList = lazy.elementList.reduce((a,b,i) => {
                        if(loadedIndex.indexOf(i) === -1) {
                            a.push(b);
                        }
                        return a;
                    }, [])
                }
            }
        }
        fn(this);
        tools.doOnScroll({fn: fn, arg: this}, true, true);
    }

    getRootMarginString() {
        return this.rootMargin.top + 'px ' + this.rootMargin.right + 'px ' + this.rootMargin.bottom + 'px ' + this.rootMargin.left + 'px ';
    }
}

var RateLimit = function(maxOps, interval, allowBursts) {
    this._maxRate = allowBursts ? maxOps : maxOps / interval;
    this._interval = interval;
    this._allowBursts = allowBursts;

    this._numOps = 0;
    this._start = new Date().getTime();
    this._queue = [];
};

RateLimit.prototype.schedule = function(fn) {
    var that = this,
        rate = 0,
        now = new Date().getTime(),
        elapsed = now - this._start;

    if (elapsed > this._interval) {
        this._numOps = 0;
        this._start = now;
    }

    rate = this._numOps / (this._allowBursts ? 1 : elapsed);

    if (rate < this._maxRate) {
        if (this._queue.length === 0) {
            this._numOps++;
            fn();
        }
        else {
            if (fn) this._queue.push(fn);

            this._numOps++;
            this._queue.shift()();
        }
    }
    else {
        if (fn) this._queue.push(fn);

        setTimeout(function() {
            that.schedule();
        }, 1 / this._maxRate);
    }
};

export default Lazy;
