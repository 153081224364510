const tools = require('./tools');
class Form {
    constructor(formEl, statusEl) {
        this.formEl = formEl;
        this.statusEl = statusEl;
        this.formFields = [];
        this.fileFields = [];
        this.submitBtn = null;
        this.validity = true;
        this.states = {valid: 3, progress: 2, invalid: 1};
    }

    init() {
        this.formEl.addEventListener('submit', () => {
            return this.validity;
        })

        this.handleStatus();
    }

    handleStatus() {
        if(this.statusEl) {
            let timeout = null;

            let changeEventHandler = () => {
                if(timeout) {
                    window.clearTimeout(timeout);
                    timeout = null
                }
                timeout = setTimeout(()=>{
                    let fieldsValitity = true;
                    let fieldsCumulatedValidity = false;

                    for(let i = 0; i < this.formFields.length; i++) {
                        let check = this.formFields[i].check()
                        fieldsValitity &&= check;
                        fieldsCumulatedValidity ||= check;
                    }

                    if(fieldsValitity) {
                        this.setValidity(this.states.valid);
                    } else if(!fieldsCumulatedValidity){
                        this.setValidity(this.states.invalid);
                    } else {
                        this.setValidity(this.states.progress);
                    }
                }, 200)
            }

            tools.each(this.formEl.querySelectorAll('.form-row input, .form-row textarea'), fieldEl => {
                this.formFields.push(this.getFieldObj(fieldEl));
                fieldEl.addEventListener('keyup', changeEventHandler)
            })

            if(this.formFields.length > 0) {
                this.submitBtn = this.formEl.querySelector('input[type=submit]');
                this.setValidity(this.states.invalid)
            }

        }
    }

    setValidity(nb) {
        if(nb === this.states.valid) {
            this.submitBtn.removeAttribute('disabled');
            this.setStatus(nb);
        } else {
            this.submitBtn.setAttribute('disabled', 'disabled');
            this.setStatus(nb);
        }
    }

    setStatus(nb) {
        this.statusEl.classList.remove('status-1');
        this.statusEl.classList.remove('status-2');
        this.statusEl.classList.remove('status-3');
        this.statusEl.classList.add('status-' + nb);
    }

    getFieldObj(fieldEl) {
        let obj = {field: fieldEl};
        if(fieldEl.getAttribute('type') === 'email') {
            obj.check = function () {
                return this.field.value.length > 4 &&
                    (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        .test(String(this.field.value).toLowerCase());
            }
        } else if (fieldEl.getAttribute('type') === 'file'){
            let fileField = new FileUploadField(fieldEl.parentNode);
            fileField.init();
            obj.check = function() {
                return true;
            }
        } else {
            obj.check = function() {
                return this.field.value.length > 0;
            }
        }

        return obj;
    }
}


class FileUploadField {
    constructor(el) {
        this.el = el;
        this.input = el.querySelector('input[type=file]');
        this.frontEl = el.querySelector('.file-btn');
        this.labelEl = null;
        this.defaultLabel = null;
        this.removeEL = null;
    }

    init() {
        if(this.input && this.frontEl) {
            this.labelEl = this.frontEl.querySelector('span.label');
            if(this.labelEl) {
                this.defaultLabel = this.labelEl.getAttribute('data-add-label').trim();

                this.input.addEventListener('change', e => {
                    this.el.classList.add('file');
                    this.labelEl.textContent = this.input.files[0].name;
                })
            }

            this.removeEl = this.frontEl.querySelector('span.remove');
            if(this.removeEl) {
                this.removeEl.addEventListener('click', e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.input.value = '';
                    this.el.classList.remove('file');
                    this.labelEl.textContent = this.defaultLabel;
                })
            }
        }
    }
}

export default Form;
