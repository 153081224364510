const tools = require('./tools');
class Collapsible {
    constructor(elt) {
        this.wrapper = elt;
        this.collapsible = elt.querySelector('.collapsible');
        this.area = elt.querySelector('.collapsible-area');
        this.buttons = elt.querySelectorAll('.collapsible-btn');
        this.isOpen = false;

        if(this.collapsible && this.buttons && this.area) {
            this.init();
        }
    }

    init() {
        tools.each(this.buttons, btn => {
            btn.addEventListener('click', e => this.clickAction());
        });

        tools.doOnWindowResize({arg: this, fn: (arg) => {arg.resizeCollapsible()}});
    }

    clickAction() {
        this.isOpen = !this.isOpen;
        this.resizeCollapsible();
    }

    resizeCollapsible() {
        if(this.isOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    setHeights() {
        this.collapsible.style.height = this.area.offsetHeight + 'px';
        this.collapsible.style.maxHeight = this.area.offsetHeight + 'px';
    }

    open() {
        // this.isOpen = true;
        this.wrapper.classList.add('area-visible');
        this.setHeights();
        tools.each(this.buttons, btn => btn.classList.add('open'));
        setTimeout(()=>this.setHeights(), 300);
    }

    close() {
        // this.isOpen = false;
        this.wrapper.classList.remove('area-visible');
        this.collapsible.style.height = 0;
        this.collapsible.style.maxHeight = 0;
        tools.each(this.buttons, btn => btn.classList.remove('open'));
    }
}

export default Collapsible;
