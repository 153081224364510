class Cookie {
    constructor(name) {
        this.name = name;
    }

    set(value) {
        let d = new Date();
        d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
        document.cookie = this.name + "=" + value + ";expires=" + d.toUTCString() + ";SameSite=Strict;path=/";
    }

    check(value) {
        return document.cookie.indexOf(this.name + "=" + value) !== -1;
    }
}

export default Cookie;
