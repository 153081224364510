const tools = {
    doOnWindowResizeList: [],
    doOnScrollEndList: [],
    doOnScrollList: [],
    windowScrolListenerInitialised: false,
    windowResizeListenerInitialised: false,

    freezeLazy: false,

    each(list, fn) {
        if (list && fn) {
            for (let i = 0; i < list.length; i++) {
                fn(list[i], i);
            }
        }
    },

    eachReverse(list, fn) {
        if (list && fn) {
            for (let i = list.length -1 ; i>= 0; i--) {
                fn(list[i], i);
            }
        }
    },

    isEditMode() {
        return document.body.classList.contains('editmode')
    },

    goTo(targetElt, fallback) {

        tools.scrollTo(window, (window.pageYOffset || window.scrollY) + Math.ceil(targetElt.getBoundingClientRect().top), 300, () => {
            let gap = Math.ceil(targetElt.getBoundingClientRect().top);
            if(gap !== 0) {
                tools.scrollTo(window, (window.pageYOffset || window.scrollY) + gap, 0, fallback);
            } else if(fallback) {
                fallback(targetElt);
            }
        });
    },

    scrollTo(elt, destination, duration = 300, fb) {
        tools.freezeLazy = true;
        let start, distance, timeStart;
        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }


            if (timeElapsed < duration) {
                requestAnimationFrame(loop);
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                if (fb) {
                    fb(elt);
                }
                tools.freezeLazy = false;
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }


            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                //todo: result rounding as param
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },

    doOnWindowResize(action) {
        if (action && action.fn) {
            this.doOnWindowResizeList.push(action);
            if(!this.windowResizeListenerInitialised) {
                tools.windowResizeListener();
            }
        }
    },

    onWindowResizeActions() {
        tools.each(this.doOnWindowResizeList, action => {
            action.fn(action.arg);
        });
    },

    windowResizeListener() {
        let timeoutFn = null;
        window.onresize = (e) => {
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onWindowResizeActions();
            }, 100);
        };
        tools.windowResizeListenerInitialised = true;
    },

    doOnScroll(action, duringScroll, scrollEnd) {
        if (action && action.fn) {
            if(duringScroll) {
                this.doOnScrollList.push(action)
            }
            if(scrollEnd) {
                this.doOnScrollEndList.push(action);
            }
            if(!tools.windowScrolListenerInitialised) {
                tools.windowScrollListener();
            }
        }
    },

    onScrollActions() {
        tools.each(this.doOnScrollList, action => {
            action.fn(action.arg)
        });
    },

    onScrollEndActions() {
        tools.each(this.doOnScrollEndList, action => {
            action.fn(action.arg)
        });
    },

    windowScrollListener(fct) {
        let timeoutFn = null;
        window.addEventListener('scroll', (e) => {
            tools.onScrollActions();
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onScrollEndActions();
            }, 100);
        })
        tools.windowScrolListenerInitialised = true;
    },

    updateHistory(url) {
        tools.updateFHistory(null, null, url)
    },

    updateFHistory(data, title, url,) {
        url = url.replace(/~.*~/, '');
        console.log('updatefhistory', url);
        // url = encodeURI(url);
        window.history.pushState(data, title, url);
    },

    replaceHistoryState(url) {
        url = url.replace(/~.*~/, '');
        // url = encodeURI(url);
        history.replaceState(null, null, url);
    },

    setInterval(fn, delay) {

        let intervalObj = {
            start: Date.now(),
            stop: false,
            delay: delay,
            fn: fn,
            intervalFn: () => {
                Date.now() - intervalObj.start < intervalObj.delay || (intervalObj.start += intervalObj.delay, intervalObj.fn());
                intervalObj.stop || requestAnimationFrame(intervalObj.intervalFn);
            },
            clear: () => intervalObj.stop = true
        }
        requestAnimationFrame(intervalObj.intervalFn)
        return intervalObj;
    }

};

module.exports = tools;
