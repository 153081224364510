import Cookie from './Cookie';

class CookieAlert {
    constructor(elSelector, btnSelector) {
        this.cookie = new Cookie('usb-innovation-cookies-accepted')
        this.el = document.querySelector(elSelector);
        this.btn = document.querySelector(btnSelector);
    }

    init() {
        if (this.el && this.btn) {
            if (!this.cookie.check('true')) {
                this.el.classList.remove('accepted');
            }

            this.btn.addEventListener('click', evt => {
                evt.preventDefault();
                this.cookie.set('true');
                this.el.classList.add('accepted');
            });
        }
    }
}

export default CookieAlert;
